var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fullTextContent margin-20" },
    [
      _c("el-page-header", {
        attrs: { content: "用户协议" },
        on: { back: _vm.goBack },
      }),
      _c("div", {
        staticClass: "padded-20",
        domProps: { innerHTML: _vm._s(_vm.lawFullText) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }