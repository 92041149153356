<template>
  <div class="fullTextContent margin-20">
    <el-page-header @back="goBack" content="用户协议"> </el-page-header>
    <div v-html="lawFullText" class="padded-20"></div>
  </div>
</template>

<script>
import { queryUserPrivacy } from "./serviceApi";
export default {
  name: " ",
  components: {},
  props: {},
  data() {
    return {
      activeName: ["1"],
      lawFullText: null,
    };
  },
  mounted() {
    this.getUserPrivacy();
  },
  methods: {
    goBack() {
      console.log("go back");
      this.$router.push("/login");
    },
    // 获取用户协议
    async getUserPrivacy() {
      const { data } = await queryUserPrivacy();
      const { agreement } = data;
      this.lawFullText = agreement;
    },
  },
};
</script>

<style scoped lang="scss"></style>
